import { render, staticRenderFns } from "./SalesPrices.vue?vue&type=template&id=1e8bbc2d&scoped=true&"
import script from "./SalesPrices.vue?vue&type=script&lang=js&"
export * from "./SalesPrices.vue?vue&type=script&lang=js&"
import style0 from "./SalesPrices.vue?vue&type=style&index=0&id=1e8bbc2d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8bbc2d",
  null
  
)

export default component.exports