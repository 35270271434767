<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Sales Prices
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="newClick"
        :disabled="!user.can('sales-price.create')"
      >
        <v-icon
          small
          left
        >fas fa-folder-plus</v-icon>
        New
      </v-btn>
    </v-row>

    <v-data-table
      :class="{ 'clickable': user.can('sales-price.update') }"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
      <template v-slot:item.margin="{ item }">
        {{ formatPercentage(item.margin) }}
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-icon :color="item.is_active == 1 ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>

      <template v-slot:item.categories="{ item }">

        <v-chip
          v-for="categoryId in item.categories_data"
          :key="'cat'+categoryId"
          class="mr-2"
          small
          >{{ formatItem(categories, categoryId, 'name') }}</v-chip>
      </template>

      <template v-slot:item.brands="{ item }">
        <v-chip
          v-for="brandId in item.brands_data"
          :key="'brand'+brandId"
          class="mr-2"
          small
          >{{ formatItem(brands, brandId, 'name') }}</v-chip>
      </template>

      <template v-slot:item.products="{ item }">
        <v-chip
          v-for="productId in item.products_data"
          :key="'product'+productId"
          class="mr-2"
          small
          >{{ products[productId].name }}</v-chip>
      </template>

    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.description"
              :error-messages="errors.description"
              label="Description"
            ></v-text-field>

            <number-field
              outlined
              class="mb-2"
              v-model="model.margin"
              :error-messages="errors.margin"
              decimals="2"
              decimal-separator="."
              label="Margin"
              suffix="%"
            ></number-field>

            <v-autocomplete
              outlined
              class="mb-2"
              v-model="model.categories_data"
              :error-messages="errors.categories"
              clearable
              clear-icon="fas fa-times"
              :items="categories"
              item-text="name"
              item-value="id"
              label="Categories"
              multiple
              chips
            ></v-autocomplete>

            <v-autocomplete
              outlined
              class="mb-2"
              v-model="model.brands_data"
              :error-messages="errors.brands"
              clearable
              clear-icon="fas fa-times"
              :items="brands"
              item-text="name"
              item-value="id"
              label="Brands"
              multiple
              chips
            ></v-autocomplete>

            <v-autocomplete
              outlined
              class="mb-2"
              v-model="model.products_data"
              :error-messages="errors.products"
              clearable
              clear-icon="fas fa-times"
              :items="products"
              item-text="name"
              item-value="id"
              label="Products"
              multiple
              chips
            ></v-autocomplete>

            <v-checkbox
              v-model="model.is_active"
              :error-messages="errors.is_active"
              label="Active"
              :false-value="0"
              :true-value="1"
            ></v-checkbox>


          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-btn
            v-if="model.id"
            color="error"
            class="px-5"
            large
            outlined
            depressed
            @click="$refs.deleteSalesPriceConfirmDialog.show()"
          >Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Save</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <confirm-dialog
      ref="deleteSalesPriceConfirmDialog"
      title="Delete"
      message="Are you sure?"
      label-cancel="Cancel"
      @confirm="deleteSalesPrice()"
    ></confirm-dialog>


  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'
import NumberField from '@/components/NumberField'
import { formatPercentage, parseJson } from '@/utils'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    NumberField,
    ConfirmDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')
    const { user } = api

    const itemCollection = api.collection('sales-prices', {
      params: {
      },
      afterFind: item => {
        item.categories_data = parseJson(item.categories, [])
        item.brands_data = parseJson(item.brands, [])
        item.products_data = parseJson(item.products, [])
      },
      store: true,
    })

    const { items, totalCount, options, isSearching } = itemCollection

    const headers = [
      { text: 'Description', value: 'description' },
      { text: 'Categories', value: 'categories' },
      { text: 'Brands', value: 'brands' },
      { text: 'Products', value: 'products' },
      { text: 'Margin', value: 'margin', align: 'center' },
      { text: 'Active', value: 'is_active', align: 'center' },
    ]

    const dialog = ref(false)

    const { items: categories } = api.collection('categories').all()
    const { items: brands } = api.collection('brands').all()
    const { items: products } = api.collection('products', {
      params: {
        fields: 'id,name',
      },
    }).all()

    function formatItem(items, id, attribute = null) {
      let item = items ? items.find(item => item.id == id) : undefined
      return item && attribute ? item[attribute] : item
    }

    const itemModel = api.model('sales-prices/:id', {
      params: {
      },
      afterFind: model => {
        model.setAttribute('categories_data', model.getJsonAttribute('categories') || [])
        model.setAttribute('brands_data', model.getJsonAttribute('brands') || [])
        model.setAttribute('products_data', model.getJsonAttribute('products') || [])
      },
      beforeSave: model => {
        model.setJsonAttribute('categories', model.getAttribute('categories_data', []))
        model.setJsonAttribute('brands', model.getAttribute('brands_data', []))
        model.setJsonAttribute('products', model.getAttribute('products_data', []))
      },
    })
    const { attributes: model, errors, isBusy } = itemModel

    loader.bindRef(isBusy)

    function newClick() {
      itemModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      if (user.can('sales-price.update')) {
        itemModel.find(item.id).then(() => {
          dialog.value = true
        })
      }
    }

    function saveClick() {
      itemModel.save().then(() => {
        itemCollection.search()
        dialog.value = false
      })
    }

    function deleteSalesPrice() {
      itemModel.delete().then(() => {
        itemCollection.search()
        dialog.value = false
      })
    }

    return {
      user,
      headers,
      items,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteSalesPrice,
      dialog,
      model,
      categories,
      brands,
      products,
      errors,
      formatPercentage,
      formatItem,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
